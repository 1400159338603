function Stuff() {

    const textFiles = ['albums.txt']

    return (
        <div>
            {textFiles.map((fileName, index) => (
                <button key={index}>
                    <a href={process.env.PUBLIC_URL + '/' + fileName} style={{textDecoration:'none', color:'inherit'}}>
                        {fileName}
                    </a>
                </button>
            ))}
        </div>
    );
}

export default Stuff;