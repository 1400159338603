import React from 'react';
import ReactDOM from 'react-dom/client';
import Stuff from './Stuff';

// import './index.css';
import {Link} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
document.title = 'Jor Rocks'
root.render(
  <div>
    <h1>hello poop</h1>
    <Stuff />
  </div>
); 